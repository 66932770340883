.affiliates {

    &__img {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        filter: grayscale(100%);
        filter: gray;
        transition: all .6s ease;

        &:hover, 
        &:focus {
            box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.4), 0 8px 22px 0 rgba(0, 0, 0, 0.21);
            filter: grayscale(0%);
            filter: none;
        }
    }
}