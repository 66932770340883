.footer{
    // background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    background-color: #000;
    color: rgba($brand-white, 0.7);
  
    h2{
      color: rgba($brand-red, 1);
    }
  }
  
  .footer-banner{
    position: relative;
    padding: 1% 0;
  
    &__logo{
      position: absolute;
      top: 0;
      right: 128px;
  
      @include media-breakpoint-down(md){
        right: 25%;
      }
    }
  }
  