.history{
    text-align: left;
    background-color: rgba($brand-grey, 0.2);
    color: $brand-black;
  
    h2{
      color: rgba($brand-black, 1);
    }
  
    p{
      color: rgba($brand-grey, 1);
    }
  
    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }
  