/* ================================================================
   Global Styles
   ================================================================ */
body {
  position: relative;
  overflow-x: hidden;
  // background-color: green;
  // overflow: hidden;
  // height: 100%;
}

img {
  max-width: 100%;
  height: auto;
}

%headings {
  // font-family: eurostile, sans-serif;
  // font-style: normal;
  // font-weight: bold;
}

h1 {
  // banner title styles 
  // @extend %headings;
  // font-size: 3.917rem;
  // line-height: 4.667rem;
}

h2 {
  // section title styles
  // @extend %headings;
  // font-size: 3.333rem;
  // line-height: 4rem;
}

h3 {
  // banner header 2
  // @extend %headings;
  // font-size: 2.333rem;
  // line-height: 2.833rem;
}

h4 {
  // banner header 3
  // @extend %headings;
  // font-size: 2.083rem;
  // line-height: 2.5rem;
}

p {
  // font-family: Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  // font-size: 1.417rem;
  // font-style: normal;
  // line-height: 2.417rem;
}

blockquote {
  // font-family: Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  // font-size: 2rem;
  // font-style: italic;
  // line-height: 2.25rem;
}

span.red {
  color: rgba($brand-red, 1);
  font-weight: bolder;
}

span.grey {
  color: rgba($brand-grey, 1);
}

span.white {
  color: rgba($brand-white, 1);
}

span.black {
  color: rgba($brand-black, 1);
}

/* ================================================================
   Responsive Styles
   ================================================================ */
@include media-breakpoint-up(md) {
  body {
   //  padding-top: 7rem;
  }
}

@include media-breakpoint-up(lg) {
  body {
   //  padding-top: 8rem;
  }
}
