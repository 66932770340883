// Bootstrap Overrides

// Colors
$brand-grey:   #b3b3b3;
$brand-white:  #fff;
$brand-red:	   #f00;
$brand-black:  #000;
//
$theme-colors: (
  "primary": $brand-grey,
  "light": $brand-white,
  "dark": $brand-black,
  "danger": $brand-red
);

// Include parts of Bootstrap

// Required
@import "../../node_modules/bootstrap/scss/bootstrap";
// @import "../../node_modules/bootstrap/scss/functions";
// @import "../../node_modules/bootstrap/scss/variables";
// @import "../../node_modules/bootstrap/scss/mixins";
//
// // Optional
// @import "../../node_modules/bootstrap/scss/reboot";
// @import "../../node_modules/bootstrap/scss/type";
// @import "../../node_modules/bootstrap/scss/images";
// @import "../../node_modules/bootstrap/scss/buttons";
// @import "../../node_modules/bootstrap/scss/grid";
// @import "../../node_modules/bootstrap/scss/utilities";
// @import "../../node_modules/bootstrap/scss/nav";
// @import "../../node_modules/bootstrap/scss/navbar";
// @import "../../node_modules/bootstrap/scss/close";

// Font Awesome & Overrides

@import "../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";

// MFB Override
$main-color: #f00;
$number-of-child-buttons: 10;

/* Import Internal SCSS
**********************/
@import "base/variables";
@import "base/mixins";
@import "base/global";
@import "modules/hero";
@import "modules/navbar";
@import "modules/navbar-test";
@import "modules/champions";
@import 'modules/sprite';
@import "modules/sections";
@import "modules/volunteer/hero";
@import "modules/volunteer/about";
@import "modules/quotes/hero";
@import "modules/quotes/about";
@import "modules/about";
@import "modules/about/hero";
@import "modules/about/history";
@import "modules/help/hero";
@import "modules/testimonials";
@import "modules/services";
@import "modules/affiliates";
@import "modules/staff";
@import "modules/footer";
@import "../../node_modules/mfb/src/mfb.scss";
