.subHero1 {
    position: relative;
    color: $brand-white;
    background-color: rgba($brand-white, 1);
    margin-top: -4.5rem;
  
    &__bg{
      background-color: rgba($brand-white, 1);
      height: 96vh;
      @include bg-img-light('/assets/images/fire--smaller.jpg');
  
      @include media-breakpoint-up(sm) {
        @include bg-img-light('/assets/images/fire--small.jpg');
      }
  
      @include media-breakpoint-up(md) {
        @include bg-img-light('/assets/images/fire--medium.jpg');
        margin-top: -5.5rem;
      }
  
      @include media-breakpoint-up(lg) {
         @include bg-img-light('/assets/images/fire--large.jpg');
      }
  
      @include media-breakpoint-up(xl) {
         @include bg-img-light('/assets/images/fire.jpg');
      }
    }
  
    &__content {
      background-color: rgba($brand-black, 0.6);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 2% 5%;
      // margin-right: 3%;
    }
  
    // @include media-breakpoint-down(sm) {
    //   &__content {
    //     background-color: rgba($brand-black, 0.3);
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     transform: translate(-50%, -50%);
    //     padding: 2% 5%;
    //   }
    // }
  } // ./subHero1
  
  .charity {
    position: relative;
  }
  
  @include media-breakpoint-down(md) {
    .infographic {
      margin-bottom: 20%;
    }
  }
  
  @include media-breakpoint-up(md) {
    .charity {
      text-align: left;
    }
  }
  
  .card-columns {
    @include media-breakpoint-only(sm) {
      column-count: 1;
    }
    @include media-breakpoint-only(md) {
      column-count: 2;
    }
    @include media-breakpoint-only(lg) {
      column-count: 3;
    }
    @include media-breakpoint-only(xl) {
      column-count: 3;
    }
  }
  
  .charity-card {
    opacity: 0;
    transition: all 0.5s ease-out;
  }
  
  .food {
    opacity: 0;
    transition: all 0.5s ease-out;
    width: 50%;
  }
  
  .fade-in{
    opacity: 1;
  }
  