.spacer{
    padding: 150px 0;
    background-color: rgba($brand-black, 1);
    text-align: center;
    position: relative;
    overflow: hidden;
  
    &__bg{
      @include bg-img('/assets/images/hero--smaller.jpg');
      position: absolute;
      width: 100%;
      height: 120%;
      top: 0;
      z-index: 1;
  
      @include media-breakpoint-up(sm) {
        @include bg-img('/assets/images/hero--small.jpg');
      }
  
      @include media-breakpoint-up(md) {
        @include bg-img('/assets/images/hero--medium.jpg');
      }
  
      @include media-breakpoint-up(lg) {
        @include bg-img('/assets/images/hero--large.jpg');
      }
  
      @include media-breakpoint-up(xl) {
        @include bg-img('/assets/images/hero.jpg');
      }
    }
  
    &__content{
      background-color: rgba($brand-white, 0.85);
      color: #000;
      width: 90%;
      margin: 0 auto;
      max-width: 1140px;
      padding: 1% 3%;
      position: relative;
      z-index: 2;
  
      @include media-breakpoint-up(md) {
        width: 60%;
      }
  
      &-anchor {
        position: relative;
        bottom: 0;
      }
    }
  
  }
  