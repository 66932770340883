// Edit gulpfile.js/templates/sprite.scss file and run gulp task in order to update src/styles/modules/_sprite.scss
    .icon {
      background-image: url('../images/sprite/sprite-6018588d.svg');
    }
  .icon--bts {
    width: 284px;
    height: 142px;
    background-position: 0 0;
  }
  .icon--bts-small {
    width: 108px;
    height: 108px;
    background-position: 37.48271092669433% 97.93103448275862%;
  }
  .icon--compass {
    width: 273.5px;
    height: 276.4px;
    background-position: 50.94170403587444% 0;
  }
  .icon--compass-wh {
    width: 273.5px;
    height: 276.4px;
    background-position: 100% 0;
  }
  .icon--gi-logo-dark {
    width: 284px;
    height: 142px;
    background-position: 0 55.46875%;
  }
  .icon--gi-logo-dark-small {
    width: 108px;
    height: 108px;
    background-position: 52.42047026279391% 97.93103448275862%;
  }
  .icon--navigators--small {
    width: 271px;
    height: 113.1px;
    background-position: 0 99.68409968409969%;
  }
