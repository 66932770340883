.quotes__hero {
    position: relative;
    color: rgba($brand-black, 1);
    background-color: rgba($brand-grey, 1);
    margin-top: -4.5rem;
  
    &-bg{
      background-color: rgba($brand-grey, 1);
      height: 96vh;
      @include bg-img-grey('/assets/images/quotes--smaller.jpg');
  
      @include media-breakpoint-up(sm) {
        @include bg-img-grey('/assets/images/quotes--small.jpg');
      }
  
      @include media-breakpoint-up(md) {
        @include bg-img-grey('/assets/images/quotes--medium.jpg');
        margin-top: -5.5rem;
      }
  
      @include media-breakpoint-up(lg) {
         @include bg-img-grey('/assets/images/quotes--large.jpg');
      }
  
      @include media-breakpoint-up(xl) {
         @include bg-img-grey('/assets/images/quotes.jpg');
      }
    }
  
    &-content {
      background-color: rgba($brand-white, 0.6);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 2% 5%;
      // margin-right: 3%;

      &-small {
          font-weight: bolder;
      }

      &-big {
          font-size: 4rem;
      }
    }
  
    // @include media-breakpoint-down(sm) {
    //   &-content {
    //     background-color: rgba($brand-white, 0.6);
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     transform: translate(-50%, -50%);
    //     padding: 2% 5%;
    //   }
    // }
  } // ./quote__hero