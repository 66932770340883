/* ================================================================
   BACKGROUND GRADIENT IMG MIXINS
   ================================================================ */
@mixin bg-img-light($imgpath, $position:center, $repeat: no-repeat, $size: cover) {
    background: {
        image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)), url($imgpath);
        position: $position;
        repeat: $repeat;
        size: $size;
    }
}

@mixin bg-img-grey($imgpath, $position:center, $repeat: no-repeat, $size: cover) {
    background: {
        image: linear-gradient(to bottom, rgba(179, 179, 179, 0), rgba(179, 179, 179, 0), rgba(179, 179, 179, 0), rgba(179, 179, 179, 0), rgba(179, 179, 179, 1)), url($imgpath);
        position: $position;
        repeat: $repeat;
        size: $size;
    }
}

@mixin bg-img-dark($imgpath, $position:center, $repeat: no-repeat, $size: cover) {
    background: {
        image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)), url($imgpath);
        position: $position;
        repeat: $repeat;
        size: $size;
    }
}

@mixin bg-img($imgpath, $position:center, $repeat: no-repeat, $size: cover) {
    background: {
        image: url($imgpath);
        position: $position;
        repeat: $repeat;
        size: $size;
    }
}

/* ================================================================
   CARDS BOX SHADOW AND TRANSITIONS
   ================================================================ */
@mixin gates-card($shadow1, $shadow2, $transition:.3s, $radius: 1%) {
    box-shadow: $shadow1, $shadow2;
    transition: $transition;
    border-radius: $radius;

    &:hover,
    &:active,
    &:focus{
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 12px 40px 0 rgba(0, 0, 0, 0.19)
    }
}