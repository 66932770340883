.page-section{
    padding: 10% 0;
    text-align: center;
  
    &__title{
      padding: 0 0 3% 0;
    }
  }
  
  hr {
  
    &.light{
      margin-top: 0;
      margin-bottom: 0;
      border: 0;
      border-top: 1px solid rgba($brand-white, 1);
    }
  
    &.grey{
      margin-top: 1rem;
      margin-bottom: 5rem;
      border: 0;
      border-top: 1px solid rgba($brand-grey, 0.5);
      width: 50%;
    }
  
    &.dark{
      margin-top: 1rem;
      margin-bottom: 5rem;
      border: 0;
      border-top: 1px solid rgba($brand-black, 1);
    }
  
    &.red{
      margin-top: 1rem;
      margin-bottom: 5rem;
      border: 0;
      border-top: 1px solid rgba($brand-red, 1);
    }
  }
  