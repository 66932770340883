.about{
    text-align: left;
    background-color: rgba($brand-black, 1);
    color: $brand-white;
    position: relative;
  
    h2{
      color: rgba($brand-white, 1);
    }
  
    p{
      color: rgba($brand-grey, 1);
    }
  
    &__text {
      opacity: 0;
      transition: all 0.5s ease-out;
    }
  
    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }
  
  .history {
  
    &__text {
      opacity: 0;
      transition: all 0.5s ease-out;
    }
  }
  
  .fade-in{
    opacity: 1;
  }
  
  @include media-breakpoint-down(sm) {
    .small-margin {
      margin-bottom: 20%;
    }
  }