.menu__top {
  background-color: rgba($black, 1);
  line-height: 3.5rem;
}

.navbar {
  transition: all 0.5s ease-out;
  background-color: rgba($white, 0.5);
  height: 4.5rem;

  &.scrolled {
    background-color: rgba($white, 1);
    transition: all 0.5s ease-out;
  }

  &-collapse {
    background-color: rgba($white, 0.9);
  }

  .logo__graphic {
    text-indent: -9999px;
  }

  .mobile__logo {
    position: relative;
    top: -1.6rem;
    transform-origin: left;
    transform: scale(0.5);
  }

  .navbar-toggler {
    width: 56px;
    height: 54px;
    position: relative;
    top: -1.6rem;
  }

  &-nav__img {
    width: 25%;
  }
}

@include media-breakpoint-up(sm) {
}

@include media-breakpoint-up(md) {
  .navbar {
    height: 5.5rem;
    background-color: rgba($white, 0.5);

    .desktop__logo {
      transform-origin: left;
      transform: scale(0.5);
    }

    &-collapse {
      background-color: rgba($white, 0);
    }

    &-nav__img {
      width: 90%;
      position: relative;
      left: -0.5rem;
    }
  }
}

@include media-breakpoint-up(lg) {
}

@include media-breakpoint-up(xl) {
}
