.staff {
  &__slide {
    height: 100vh;
    overflow: hidden;
    position: relative;

    &-pin-wrapper {
      // because it collapses on scroll
      height: 100%;
      width: 110%; // changed bc new images kept getting clipped on right
    }

    &-overlay {
      position: absolute;
      top: 8.1%;
      bottom: 0;
      left: 8.1%;
      right: 0;
      height: 83%;
      width: 83%;
      opacity: 0;
      transition: 0.5s ease;
      background-color: rgba($brand-red, 0.8);

      &-text {
        font-size: 1rem;
        color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
      }
    }

    &-img {
      height: 500px;
      position: relative;
      width: 500px;

      &:hover .staff__slide-overlay,
      &:active .staff__slide-overlay {
        opacity: 1;
      }
    }
  }

  &__slide01 .staff__slide-img {
    background-image: url('/assets/images/mark-vardy.png');
  }

  &__slide02 .staff__slide-img {
    background-image: url('/assets/images/brian-haley.png');
  }

  &__slide03 .staff__slide-img {
    background-image: url('/assets/images/sharri-russel.png');
  }

  &__slide04 .staff__slide-img {
    background-image: url('/assets/images/bonnie-sawler.png');
  }

  &__slide05 .staff__slide-img {
    background-image: url('/assets/images/jan-buist.png');
  }

  &__slide06 .staff__slide-img {
    background-image: url('/assets/images/sarah-kenny.png');
  }

  &__slide07 .staff__slide-img {
    background-image: url('/assets/images/penney.png');
  }

  &__slide02 .staff__slide-pin-wrapper,
  &__slide03 .staff__slide-pin-wrapper,
  &__slide04 .staff__slide-pin-wrapper,
  &__slide05 .staff__slide-pin-wrapper,
  &__slide06 .staff__slide-pin-wrapper,
  &__slide07 .staff__slide-pin-wrapper {
    transform: translateY(-100%);
  }

  @include media-breakpoint-down(md) {
    text-align: center;
  }

  @include media-breakpoint-up(md) {
    text-align: left;
  }

  @include media-breakpoint-down(sm) {
    &__slide {
      height: 100vh;
      overflow: hidden;

      &-pin-wrapper {
        // because it collapses on scroll
        height: 100%;
        width: 100%; // changed bc new images kept getting clipped on right
      }

      &-img {
        height: 350px;
        width: 350px;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &__slide01 .staff__slide-img {
      background-image: url('/assets/images/mark-vardy-sm.png');
    }

    &__slide02 .staff__slide-img {
      background-image: url('/assets/images/brian-haley-sm.png');
    }

    &__slide03 .staff__slide-img {
      background-image: url('/assets/images/sharri-russel-sm.png');
    }

    &__slide04 .staff__slide-img {
      background-image: url('/assets/images/bonnie-sawler-sm.png');
    }

    &__slide05 .staff__slide-img {
      background-image: url('/assets/images/jan-buist-sm.png');
    }

    &__slide06 .staff__slide-img {
      background-image: url('/assets/images/sarah-kenny-sm.png');
    }

    &__slide07 .staff__slide-img {
      background-image: url('/assets/images/penney-sm.png');
    }
  }
} // ./staff

.scrollmagic-pin-spacer {
  // this is a scrollmagic property
  position: absolute !important; // need to add important because javascript overrides this otherwise
  clip: rect(auto, auto, auto, auto);
}
