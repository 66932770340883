.hero{
    position: relative;
    color: $brand-white;
    background-color: rgba($brand-black, 1);
    margin-top: -4.5rem;
  
    &__bg{
      background-color: rgba($brand-black, 1);
      height: 96vh;
      @include bg-img-dark('/assets/images/map--small.jpg');
  
      @include media-breakpoint-up(sm) {
        @include bg-img-dark('/assets/images/map--small.jpg');
      }
  
      @include media-breakpoint-up(md) {
        @include bg-img-dark('/assets/images/map--medium.png');
        margin-top: -5.5rem;
      }
  
      @include media-breakpoint-up(lg) {
        @include bg-img-dark('/assets/images/map--large.png');
      }
  
      @include media-breakpoint-up(xl) {
        @include bg-img-dark('/assets/images/map.png');
      }
    } // ./hero__bg
  
    &__content {
      background-color: rgba($brand-black, 0.6);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 2% 5%;
  
      &-dot {
        color: $brand-red;
        font-size: 2.5rem;
        padding: 5px;
      }
  
      &-big {
        color: $brand-white;
        font-size: 4rem;
      }
    } // ./hero-content
  } // ./hero
  