.helpHero{
    position: relative;
    color: $brand-black;
    background-color: rgba($brand-white, 1);
    margin-top: -4.5rem;
  
    &__bg{
      background-color: rgba($brand-white, 1);
      height: 96vh;
      @include bg-img-light('/assets/images/parking-lot--smaller.jpg');
  
      @include media-breakpoint-up(sm) {
        @include bg-img-light('/assets/images/parking-lot--small.jpg');
      }
  
      @include media-breakpoint-up(md) {
        @include bg-img-light('/assets/images/parking-lot--medium.jpg');
        margin-top: -5.5rem;
      }
  
      @include media-breakpoint-up(lg) {
        @include bg-img-light('/assets/images/parking-lot--large.jpg');
      }
  
      @include media-breakpoint-up(xl) {
        @include bg-img-light('/assets/images/parking-lot.jpg');
      }
    }
  
    &__content {
      background-color: rgba($brand-black, 0.6);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 2% 5%;
    }
  } // ./subHero
  