.subHero{
    position: relative;
    color: $brand-white;
    background-color: rgba($brand-black, 1);
    margin-top: -4.5rem;
  
    &__bg{
      background-color: rgba($brand-black, 1);
      height: 96vh;
      @include bg-img-dark('/assets/images/spacer--smaller.jpg');
  
      @include media-breakpoint-up(sm) {
        @include bg-img-dark('/assets/images/spacer--small.jpg');
      }
  
      @include media-breakpoint-up(md) {
        @include bg-img-dark('/assets/images/spacer--medium.jpg');
        margin-top: -5.5rem;
      }
  
      @include media-breakpoint-up(lg) {
        @include bg-img-dark('/assets/images/spacer--large.jpg');
      }
  
      @include media-breakpoint-up(xl) {
        @include bg-img-dark('/assets/images/spacer.jpg');
      }
    }
  
    &__content {
      background-color: rgba($brand-black, 0.6);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 2% 5%;
    }
  } // ./subHero
  