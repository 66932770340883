.foodDrive {
  position: relative;

  &__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    transition: all 1s ease;
    background-color: rgba($brand-red, 0);
    opacity: 0;

    &-text {
      color: rgba($white, 1);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      transition: all 1s ease;

      a {
        color: rgba($black, 1);
        text-transform: uppercase;
      }
    }

    &:hover, &:focus {
      background-color: rgba($brand-red, 1);
      opacity: 1;
    }
  }
}

.services{
    position: relative;
    background-color: rgba($brand-grey, 0.2);
  
    &__box{
      background-color: rgba($brand-white, 1);
      padding: 2%;
      margin-bottom: 4%;
      border-radius: 1%;
      // box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      transition: 0.3s;
  
      &:hover{
        // box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }
    }
  
    &__box, &__icon{
      opacity: 0;
      transition: all 0.5s ease-out;
    }
  
    h2{
      color: rgba($brand-black, 1);
    }
  
    h3{
      color: rgba($brand-black, 0.5);
    }
  
    p{
      color: rgba($brand-black, 1);
    }
  
    .fade-in{
      opacity: 1;
    }
  }
  
  .compass {
    // width: 10%;
    position: absolute;
    margin-left: 3%;
    // bottom: 0;
    // left: 0;
    // padding: 1%;
  }
  
  .compass-wh {
    // width: 10%;
    position: absolute;
    // bottom: 0;
    right: 20px;
    // padding: 1%;
  }
  
  @include media-breakpoint-down(sm) {
    .compass, .compass-wh {
      left: 50%;
      transform: translate(-50%, 0);
    }
  
    .services__box {
      margin-bottom: 20%;
    }
  }
  